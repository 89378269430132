import React, { useContext } from 'react';
import { LangContext } from '../../containers/context/LangContext';

const ForgetPassword = ({ state, setState, onSubmitHandler = () => {} }) => {
  const { translations } = useContext(LangContext);

  return (
    <div class="page-contents">
      <div class="utility-page-wrap bg-gray-1">
        <div class="container">
          <div class="content-width-medium align-center">
            <div class="form-heading account-form-heading">
              <h1>{translations.mainTitle}</h1>
              <div class="large-text">{translations.mainSubtitle}</div>
            </div>
            <div class="form-block w-form">
              <form
                onSubmit={onSubmitHandler}
                id="email-form"
                name="email-form"
                data-name="Email Form"
                class="form-grid-vertical"
              >
                <input
                  value={state.email}
                  onChange={(e) => {
                    setState({ email: e.target.value.trim() });
                  }}
                  type="email"
                  class="form-input form-input-large form-input-dark w-input"
                  name="email"
                  data-name="Email"
                  placeholder={translations.emailPlace}
                  id="email"
                />
                <input
                  type="submit"
                  value={translations.sumbitBtnText}
                  class="button button-large bg-primary-4 w-button"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
