
import React, { useContext } from "react";
import ForgetPassword from "../ForgetPassword/ForgetPassword";
import useSetState from '../../utils/useSetState';
import onSubmitHandler from './utils/onSubmitHandler';
import { FeedbackContext } from "../context/FeedbackContext/FeedbackContext";

const ForgetPasswordWrapper = () => {

  const [state, setState] = useSetState({
    email: '',
    isLoading: false
  });

  const { setFeedbackState } = useContext(FeedbackContext);

  return (
    <ForgetPassword 
      state={state} 
      setState={setState}
      onSubmitHandler={onSubmitHandler.bind(null, {state, setState, setFeedbackState})}
    />
  );
};

export default ForgetPasswordWrapper;
