import axios from 'axios';
import { api_base_url } from '../../../data/client';
import is_email_valid from '../../../utils/is_email_valid';

const no_email_message = 'Please provide an email';
const email_message = 'Email Sent!';

const onSubmitHandler = async ({ setState, state, setFeedbackState }, e) => {
  try {
    e.preventDefault();

    const { email } = state;

    if (!email) {
      setFeedbackState({
        isActive: true,
        message: no_email_message,
        isError: true,
        isLoading: false
      });
    } else if (!is_email_valid(email)) {
      setFeedbackState({
        isActive: true,
        message: 'Please provide a valid email',
        isError: true,
        isLoading: false
      });
    } else {
      setFeedbackState({
        isActive: true,
        isError: false,
        isLoading: true
      });

      const res = await axios({
        url: `${api_base_url}/users/reset_password`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        },
        data: JSON.stringify({ email })
      });

      if (!res.data.error) {
        setFeedbackState({
          isActive: true,
          message: res.data.message,
          isError: false,
          isLoading: false
        });
      } else {
        setFeedbackState({
          isActive: true,
          message: res.data.message,
          isError: true,
          isLoading: false
        });
      }
    }
  } catch (err) {
    setFeedbackState({
      isActive: true,
      message: 'Unexpected Error, contact us please',
      isError: true,
      isLoading: false
    });
  }
};

export default onSubmitHandler;
