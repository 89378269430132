import React from 'react';
import ForgetPasswordWrapper from '../containers/ForgetPasswordWrapper/ForgetPasswordWrapper';
import PageWrapper from '../containers/PageWrapper';
import WebsiteWrapper from '../parts/WebsiteWrapper';

const forgetPasswordPage = ({ pageContext, location }) => {
  const { lang, translations } = pageContext;

  return (
    <div>
      <PageWrapper lang={lang} translations={translations}>
        <WebsiteWrapper isNavBgWhite={true} location={location}>
          <ForgetPasswordWrapper />
        </WebsiteWrapper>
      </PageWrapper>
    </div>
  );
};

export default forgetPasswordPage;
